 html,body { height: 100%; margin: 0px; padding: 0px; }
#full { background: #0f0; height: 100% }


.brand-text{
text-transform: none !important;
}

.page-wrapper,.wrapper{
    height: 100% !important;
}

.scroll-panel{
    overflow-y: scroll; 
    height:100%;
    
}



.scroll-panel-sendingdomains{
   text-decoration:none;
}


.spfbox, .domainbox{
    height:775px !important;
    
}


.domaincontainer {
   text-decoration:none;
   height:685px;
   overflow-y:scroll;
   list-style: none !important;
}

.selecteddomaincontainer,.registereddomaincontainer{
    height:100%;
}

.senderlistcontainer, .dnsrecordscontainer, .livefeedcontainer{
    text-decoration:none;
    height:530px;
    list-style: none !important;
    padding:0;
    border: 1px solid #f2f2f2;
    overflow: hidden;
    overflow-y: scroll;
}

.dnsrecordscontainer,.livefeedcontainer{
	height:685px;
	
}

.tab-pane{
    height:700px;
    border: 1px solid #dedede;
}

.spfconfigcontainer{
    
}
 .dmarcconfigcontainer{
    
}
.authsenders{
	border:1px solid blue;
}
.font-30{
font-size:30px !important;
}