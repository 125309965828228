/*--------------------------------------------------
07 .Portfolio Filters
---------------------------------------------------*/

#filters {
	margin: 0;
	text-align: center;
	width: 100%;
	opacity:1;
	max-height:200px;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

#filters.filters-hide {
	opacity:0;
	max-height:0px;
}

#filters li {
	display: inline-block;
}

#filters li a {
	display:inline-block;
	text-transform:uppercase;
	color:#999999;
}

#filters li a:hover {
	color:#34d5cb;
}

#filters li a.active {
	color:#272727;
}



/*--------------------------------------------------
08. Portfolio Items
---------------------------------------------------*/

#portfolio-wrap {
	width:100%;
	margin:0 auto;		
}

#portfolio-wrap.no-gutter {
	width:1280px;
	margin:0 auto;		
}

#portfolio {
	width: 100%;
	-webkit-transition: height 0.3s ease-in-out;
	-moz-transition: height 0.3s ease-in-out;
	-o-transition: height 0.3s ease-in-out;
	-ms-transition: height 0.3s ease-in-out;
	transition: height 0.3s ease-in-out;
}

.item {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.item-content {
	height:100%;
	width:100%;
	position:absolute;
	background-color:#eeeeee;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	text-align:center;
	padding:0px;
	background-size:cover;
	background-position:center center;
	overflow:hidden;
	display: table;
}


.item-overlay {
	height:100%;
	width:100%;
	background:rgba(0,0,0,0);
	position:absolute;
	opacity:0;
	box-sizing:border-box;	
	-webkit-transition: all 0.6s ease-in-out;
	-moz-transition: all 0.6s ease-in-out;
	-o-transition: all 0.6s ease-in-out;
	-ms-transition: all 0.6s ease-in-out;
	transition: all 0.6s ease-in-out;		
}

.padding-overlay {
	padding:0px;
	box-sizing:border-box;
	display:table;
	position:absolute;
	width:100%;
	height:100%;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

.item:hover .padding-overlay {
	padding:30px;
	-webkit-transition: all 0.2s ease-in-out 0.1s;
	-moz-transition: all 0.2s ease-in-out 0.1s;
	-o-transition: all 0.2s ease-in-out 0.1s;
	-ms-transition: all 0.2s ease-in-out 0.1s;
	transition: all 0.2s ease-in-out 0.1s;
}

.padding-overlay .item-overlay {
	display:table-cell;
	position:relative;
	overflow:hidden;
	text-align:center;
	vertical-align:middle;
}

.item:hover .item-overlay {
	background:rgba(0,0,0,0.8);
	opacity:1;
}

.item:hover .item-overlay.overlay-white {
	background:rgba(255,255,255,0.8);
}

.item .item-overlay.overlay-gradient {
	background: rgb(111,226,158); /* Old browsers */
	background: -moz-linear-gradient(-45deg,  rgba(111,226,158,0.8) 0%, rgba(91,218,209,0.8) 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, right bottom, color-stop(0%,rgba(111,226,158,0.8)), color-stop(100%,rgba(91,218,209,0.8))); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(-45deg,  rgba(111,226,158,0.8) 0%,rgba(91,218,209,0.8) 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(-45deg,  rgba(111,226,158,0.8) 0%,rgba(91,218,209,0.8) 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(-45deg,  rgba(111,226,158,0.8) 0%,rgba(91,218,209,0.8) 100%); /* IE10+ */
	background: linear-gradient(135deg,  rgba(111,226,158,0.8) 0%,rgba(91,218,209,0.8) 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6fe29e', endColorstr='#5bdad1',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
	opacity:0;
	-webkit-transition: all 0.6s ease-in-out;
	-moz-transition: all 0.6s ease-in-out;
	-o-transition: all 0.6s ease-in-out;
	-ms-transition: all 0.6s ease-in-out;
	transition: all 0.6s ease-in-out;
}

.item:hover .item-overlay.overlay-gradient {
	background: rgb(111,226,158); /* Old browsers */
	background: -moz-linear-gradient(-45deg,  rgba(111,226,158,0.8) 0%, rgba(91,218,209,0.8) 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, right bottom, color-stop(0%,rgba(111,226,158,0.8)), color-stop(100%,rgba(91,218,209,0.8))); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(-45deg,  rgba(111,226,158,0.8) 0%,rgba(91,218,209,0.8) 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(-45deg,  rgba(111,226,158,0.8) 0%,rgba(91,218,209,0.8) 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(-45deg,  rgba(111,226,158,0.8) 0%,rgba(91,218,209,0.8) 100%); /* IE10+ */
	background: linear-gradient(135deg,  rgba(111,226,158,0.8) 0%,rgba(91,218,209,0.8) 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6fe29e', endColorstr='#5bdad1',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
	opacity:1;
}

.item-cat {
	display:block;
	font-weight:400;
	font-size:14px;
	letter-spacing:1px;
	margin-left:-100%;
	margin-top:30px;
	color:#999;		
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.padding-overlay .item-cat {
	margin:0 20px 5px 20px;
	opacity:0;
	-ms-transform: translateY(-20px);
	-webkit-transform: translateY(-20px);
	-o-transition:translateY(-20px);
	transform: translateY(-20px);
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.item-overlay.overlay-gradient .item-cat {
	color:#fff;
}

.item:hover .item-cat {
	margin-left:30px;
	-webkit-transition: all 0.6s ease-in-out;
	-moz-transition: all 0.6s ease-in-out;
	-o-transition: all 0.6s ease-in-out;
	-ms-transition: all 0.6s ease-in-out;
	transition: all 0.6s ease-in-out;
}

.item:hover .padding-overlay .item-cat {
	margin:0 20px 5px 20px;
	opacity:1;
	-ms-transform: translateY(0px);
	-webkit-transform: translateY(0px);
	-o-transition:translateY(0px);
	transform: translateY(0px);
	-webkit-transition: all 0.5s ease-in-out 0.5s;
	-moz-transition: all 0.5s ease-in-out 0.5s;
	-o-transition: all 0.5s ease-in-out 0.5s;
	-ms-transition: all 0.5s ease-in-out 0.5s;
	transition: all 0.5s ease-in-out 0.5s;
}

.item-title {
	display:block;
	position:relative;
	font-weight: 400;
	color:#fff;
	font-size:24px;
	margin-left:-100%;
	margin-top:5px;
	margin-right:28px;
	-webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	-ms-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;
}

.padding-overlay .item-title {
	margin:5px 20px 0px 20px;
	-ms-transform: translateY(20px);
	-webkit-transform: translateY(20px);
	-o-transition:translateY(20px);
	transform: translateY(20px);
	opacity:0;
	font-size:18px;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.item-overlay.overlay-white .item-title {
	color:#272727;
}

.item:hover .item-title {
	margin-left:28px;
	-webkit-transition: all 0.6s ease-in-out 0.3s;
	-moz-transition: all 0.6s ease-in-out 0.3s;
	-o-transition: all 0.6s ease-in-out 0.3s;
	-ms-transition: all 0.6s ease-in-out 0.3s;
	transition: all 0.6s ease-in-out 0.3s;
}

.item:hover .padding-overlay .item-title {
	margin:5px 20px 0px 20px;
	opacity:1;
	-ms-transform: translateY(0px);
	-webkit-transform: translateY(0px);
	-o-transition:translateY(0px);
	transform: translateY(0px);
	-webkit-transition: all 0.5s ease-in-out 0.5s;
	-moz-transition: all 0.5s ease-in-out 0.5s;
	-o-transition: all 0.5s ease-in-out 0.5s;
	-ms-transition: all 0.5s ease-in-out 0.5s;
	transition: all 0.5s ease-in-out 0.5s;
}

.item-title:after {
	background: none repeat scroll 0 0 #666;
	bottom: -20px;
	content: "";
	height: 1px;
	left: 1px;
	position: absolute;
	width: 40px;
	opacity:0;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

.padding-overlay .item-title:after {
	content: "";
	height: 0px;
}

.item-overlay.overlay-white .item-title:after {
	background: none repeat scroll 0 0 rgba(0,0,0,0.2);
}

.item-overlay.overlay-gradient .item-title:after {
	background: none repeat scroll 0 0 rgba(255,255,255,0.4);
}

.item:hover .item-title:after {
	opacity:1;
	bottom: -15px;
	-webkit-transition: all 0.2s ease-in-out 0.9s;
	-moz-transition: all 0.2s ease-in-out 0.9s;
	-o-transition: all 0.2s ease-in-out 0.9s;
	-ms-transition: all 0.2s ease-in-out 0.9s;
	transition: all 0.2s ease-in-out 0.9s;
}



/*--------------------------------------------------
09. Portfolio Isotope Transitions
---------------------------------------------------*/

.isotope, .isotope .isotope-item {
	-webkit-transition-duration: 0.8s;
	-moz-transition-duration: 0.8s;
	-ms-transition-duration: 0.8s;
	-o-transition-duration: 0.8s;
	transition-duration: 0.8s;
}

.isotope {
	-webkit-transition-property: height, width;
	-moz-transition-property: height, width;
	-ms-transition-property: height, width;
	-o-transition-property: height, width;
	transition-property: height, width;
}

.isotope .isotope-item {
	-webkit-transition-property: -webkit-transform, opacity;
	-moz-transition-property: -moz-transform, opacity;
	-ms-transition-property: -ms-transform, opacity;
	-o-transition-property: -o-transform, opacity;
	transition-property: transform, opacity;
}

.isotope.no-transition, .isotope.no-transition .isotope-item, .isotope .isotope-item.no-transition {
	-webkit-transition-duration: 0s;
	-moz-transition-duration: 0s;
	-ms-transition-duration: 0s;
	-o-transition-duration: 0s;
	transition-duration: 0s;
}


