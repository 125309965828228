@media all and (min-width: 480px) {
  
    .spfsenderdetail{
    	border-top:0px solid silver;
    }
    
    .spfsenderdetailitem{
    	margin-left:30px;
    	margin-right:30px;
    	border-top:1px solid silver;
    	padding:3px;
    }
    
    .audittraildetail{
    margin-left:50px;
    }
    
    .addauthsendermodal,.addnewdomainmodal{
    	width:60%;
    	min-width:600px;
    }
    
	  
		
	/* Tooltip container */
	.tooltiphelp {
	  position: relative;
	  display: inline-block;
	  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
	}
	
	/* Tooltip text */
	.tooltiphelp .tooltiphelptext {
	  visibility: hidden;
	  width: 120px;
	  background: #4e9de6;
  	  border: solid 1px #4e9de6;
	  color: #fff;
	  padding: 5px 0;
	  border-radius: 6px;
	  /* Position the tooltip text - see examples below! */
	  position: absolute;
	  z-index: 1;
	   font-weight:normal;
	  
	}
	
	/* Show the tooltip text when you mouse over the tooltip container */
	.tooltiphelp:hover .tooltiphelptext {
	  visibility: visible;
	}
	
	.tooltiphelp .tooltiphelptext:after{
		  content: "";
		  position: absolute;
		  bottom: 100%;
		  left: 25%;
		  
		  border-width: 5px;
		  border-style: solid;
		  border-color: transparent transparent #4e9de6 transparent;
	  }
    .tooltiphelp .tooltiphelptext{
	  width: 240px;
	  min-height:100px;
	  top: 100%;
	  left: 5%; 
	  margin-left: -20px; /* Use half of the width (120/2 = 60), to center the tooltip */
	  text-align: left;
	  padding:8px;
	  text-transform:none;
    }
    
    .domandnsrecords{
    	width:100%px;
    	padding:10px;
    	border:1px solid black;
    	margin-top:25px;
    	border-spacing: 20px;
    	border-collapse: separate;
    }
    
    .top-bar-message{
    	padding-left:60px;
    	min-width:200px;
    	width:800px;
    	height:40px;
    	margin-top:10px;	
    	mid-width:200px;
    	padding-top:12px;
    	text-align:center;
    }
    
    .dkimkeycontainer{
    text-decoration:none;
    height:225px;
    list-style: none !important;
    padding:0;
    border: 1px solid #f2f2f2;
    overflow: hidden;
    overflow-y: scroll;
    margin-bottom:50px;
	}	
	.dkimkeyname{
		margin-bottom:10px !important;
		margin-left:15px;
	}
	.dkimkeytype{
		margin-bottom:10px !important;
		margin-left:15px;
	}
	
	.dkimkeyvalue{
	 	margin-top:0px !important;
	 	margin-left:15px;
	 	word-wrap: break-word;
	}
	
	.domaininclude{
		
		font-size:x-large;
	}

	.messages{
		width:100%;
		text-align:center;
	}
	
	code {
		color:  #3779b2 !important;
	}
}