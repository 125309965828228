@media all and (min-width: 480px) {
  .hidden{
      display:none !important;
  }

  .toggle{
      
  }
  .phone{
      
  }

   .xx-small-container{
       padding-top:5px !important;
       padding-bottom:0px !important;
   }
  .xx-small{
      height:25px !important;
      padding:0px !important;
      
      vertical-align: center!important;
  }
  .icon-check{
      font-size:12px !important;
      margin-top:-5px !important;

  }
  
  .mfacontainer{

		display:inline;
		border:1px solid red;
		
	}
	
  .mfaoptionstable{
  	width:100%;
  }
  
  .totpconfig{
    text-align:center;
  }
  
  .qrcode{
  padding:15px;
  background-color:#ffffff;
  }
  
  .profilecontainer{
  	background-color:#ffffff;
  	height:700px;
  }
  .updateprofile{
  	margin-top:60px;
  }
  .hide{
  display:none;
  }
}