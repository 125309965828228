@media all and (min-width: 480px) {
  
        
    .spfbox{
        height:680px;
        max-height:100%;
        
    }

    .spfresultscontainer{
        text-decoration:none;
        height:585px !important;
        list-style: none !important;
        padding:0;
        border: 1px solid #f2f2f2;
        overflow: hidden;
        overflow-y: scroll;
        
    }
    
    .spfresultscontainer-hidden{
    	visibility:hidden;
    }
    
    .spfresult{
    	margin-top:10px;
    	    	
    }
    .spfvalue{
    	
    }

	.ReactTable .rt-td!important{
		
		white-space:unset;
		
		
	}

	.table{background-color:#ffffff;}
	
	
	.lookupbtn{width:20%;}
	.lookupinput{width:75%;}
	
	
}

.gradecontainer{
		text-align:center;
		
	}
	
	.grade{
		width:16%;
		display:inline-block;
		padding:10px;
		text-align:center;
		color:#4a4b54;
		height:35px;
		vertical-align:middle;
		opacity:.35;
	}
	.grade_aplus{
		background-color:#63BE7B;
	}
	.grade_a{
		background-color:#A2D07F;
	}
	.grade_b{
		background-color:#E0E383;
	}
	.grade_c{
		background-color:#FDD17F;
	}
	.grade_d{
		background-color:#FA9D75;
	}
	.grade_f{
		background-color:#F8696B;
	}
	
	.grade-selected{
		font-weight:bold;
		height:40px;
		opacity:1.0;
		font-size:large;
	}
	
	.recordresult{
		display:inline;
		border:1px solid black;
	}
	.recordresultspfdisplay{
		border:1px solid green;
		margin-left:20px;
		display:inline;
	
	}
	.spferror{
		margin:10px;
		color:#F8696B;
	}
	
	.confighints{
		margin:25px;
	}
	.confighintitem{
		margin:15px;
		margin-left:45px;
		
	}
	.confighinticon{
		color:#F8696B;
		height:16px;
	}
	